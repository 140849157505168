import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { differenceInDays, formatDistance, format } from "date-fns";
import styled from "@emotion/styled";
import tw from "twin.macro";
// import Link from "gatsby-plugin-transition-link";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../../lib/helpers";
import PortableText from "./portableText";
// import Newsletter from "../Form/Newsletter";
// import BlogPostPreview from "./BlogPostPreview";
import CallToAction from "../Repeating/CTA";
import RecentPosts from "../Repeating/RecentPosts";

const StyledContent = styled.div`
  p {
    //styleName: Body Large/Large;
    font-family: Catamaran;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  ,
  span,
  li {
    ${tw`md:text-xl`}
  }
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  strong {
    ${tw`font-bold`}
  }
  em {
    ${tw`italic`}
  }
`;

function BlogPost(props) {
  const {
    siteMetadata,
    // next,
    // prev,
    slug,
    _rawBody,
    author,
    categories,
    title,
    featuredImage,
    publishedAt,
  } = props;

  const data = useStaticQuery(graphql`
    {
      sanityContactinfo {
        title
        description
        phonenumber
        email
        address
        license
        bookingurl
      }

      posts: allSanityBlogs(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              _id
              title
            }
            title
            slug {
              current
            }
            _rawBody(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `);

  const currentPostCategory =
    categories.length > 0 &&
    categories.slice(0, 1).map((category) => category.title);

  let postNodes = [];

  categories.length > 0
    ? (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
            .filter((items) =>
              items.categories.find(
                (item) => item.title === currentPostCategory
              )
            )
        : [])
    : (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : []);

  return (
    <>
      <section className="mb-8 pt-10 md:mb-10 md:pt-16">
        <div className="container">
          <div className="mx-auto max-w-[720px]">
            <header className="mb-8 md:mb-10">
              <h1>{title}</h1>
            </header>

            <div className="flex items-center justify-between space-y-8">
              <div>
                <div className="flex items-center space-x-2">
                  {author && author.image && (
                    <GatsbyImage
                      image={author.image.asset.gatsbyImageData}
                      loading="lazy"
                      className="z-0 h-12 w-12 rounded-full"
                    />
                  )}

                  <div>
                    {author && (
                      <div className="font-body text-[0.875rem] font-semibold leading-[1.25rem] text-typography-heading">
                        By <b>{author.name}</b>
                      </div>
                    )}

                    {publishedAt && (
                      <div className="font-body text-[0.875rem] font-normal leading-[1.25rem] text-typography-body">
                        {differenceInDays(new Date(publishedAt), new Date()) > 3
                          ? formatDistance(new Date(publishedAt), new Date())
                          : format(new Date(publishedAt), "MMMM d, yyyy")}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-between">
                <div className="hidden items-center space-x-6 md:flex">
                  <FacebookShareButton
                    url={`${siteMetadata.siteUrl}/${slug.current}/`}
                    quote={title}
                    className="no-underline"
                  >
                    <i className="fab fa-facebook-f text-primary-500 transition-colors duration-300 ease-linear hover:bg-primary-900"></i>
                  </FacebookShareButton>

                  <LinkedinShareButton
                    url={`${siteMetadata.siteUrl}/${slug.current}/`}
                    title={title}
                    className="no-underline"
                  >
                    <i className="fab fa-linkedin-in text-primary-500 transition-colors duration-300 ease-linear hover:bg-primary-900"></i>
                  </LinkedinShareButton>

                  <TwitterShareButton
                    url={`${siteMetadata.siteUrl}/${slug.current}/`}
                    title={title}
                    className="no-underline"
                  >
                    <i className="fab fa-twitter hover:bg-primary-900-white text-primary-500 transition-colors duration-300 ease-linear"></i>
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <article className="mb-20 md:mb-32">
        <div className="container">
          {featuredImage && featuredImage.asset && (
            <div className="mb-12 md:mb-20">
              <GatsbyImage
                image={props.featuredImage.asset.gatsbyImageData}
                loading="lazy"
                className="w-full"
              />
            </div>
          )}

          <div className="mx-auto max-w-[720px]">
            <StyledContent className="font-body text-[1.25rem] font-normal leading-[1.5rem] text-typography-body">
              {_rawBody && <PortableText blocks={_rawBody} />}
            </StyledContent>

            <div className="mb-20 mt-16 md:mb-32 md:mt-20">
              <div className="flex items-center justify-center space-x-6">
                <FacebookShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  quote={title}
                  className="no-underline"
                >
                  <i className="fab fa-facebook-f text-primary-500 transition-colors duration-300 ease-linear hover:bg-primary-900"></i>
                </FacebookShareButton>

                <LinkedinShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  title={title}
                  className="no-underline"
                >
                  <i className="fab fa-linkedin-in text-primary-500 transition-colors duration-300 ease-linear hover:bg-primary-900"></i>
                </LinkedinShareButton>

                <TwitterShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  title={title}
                  className="no-underline"
                >
                  <i className="fab fa-twitter hover:bg-primary-900-white text-primary-500 transition-colors duration-300 ease-linear"></i>
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </article>

      <RecentPosts />
      <CallToAction phonenumber={data.sanityContactinfo.phonenumber} />
    </>
  );
}

export default BlogPost;
